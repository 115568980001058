<template>
  <el-container>
    <el-header>
      <el-cascader
        placeholder="部门"
        v-model="selectedDepart"
        :options="deptOptions"
        :props="{ checkStrictly: true,value: 'id', label: 'departName', children: 'children' }"
        @change="listPostInfo()"
        ref="comCascader"
        filterable
      />
      <!--        @change="listAllDepart()"-->
      <!--     :value="options[0].value" -->
      <el-input
        v-model="queryInfo.postName"
        @blur="listPostInfo('')"
        placeholder="岗位名称"
        prefix-icon="el-icon-search"
      />
      <el-button
        type="primary"
        style="margin-left: 5px"
        icon="el-icon-plus"
        @click="showAddDialog('')"
      >
        新增
      </el-button>
    </el-header>

    <el-main>
      <el-table
        ref="multipleTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="postInfo"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        />

        <el-table-column
          align="center"
          prop="companyName"
          label="岗位所属公司"
        />

        <el-table-column
          align="center"
          prop="departName"
          label="岗位所属部门"
        />


        <el-table-column
          align="center"
          prop="postName"
          label="岗位名称"
        />

        <el-table-column
          align="center"
          prop="limitCount"
          label="招聘人数"
        />

        <el-table-column
          align="center"
          label="招聘状态"
        >
          <template v-slot="scope">
            {{ scope.row.openStatus === 1 ? '招聘中' : '禁招聘结束' }}
          </template>
        </el-table-column>


        <el-table-column
          align="center"
          label="创建时间"
        >
          <template v-slot="scope">
            {{ scope.row.createTime }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          label="状态"
        >
          <template v-slot="scope">
            {{ scope.row.enable === 1 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
        >
          <template v-slot="scope">
            <el-button
              type="text"
              size="small"
              @click="showAddDialog(scope.row.id)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="disablePost(scope.row.id)"
            >
              禁用
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delPost(scope.row.id)"
            >
              删除
            </el-button>

          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        style="margin-top: 25px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </el-main>

    <el-dialog
      title="新增岗位"
      :visible.sync="addTableVisible"
      width="60%"
      @close="resetAddForm"
      center
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-input
          v-model="addForm.id"
          type="hidden"
        />
        <el-form-item label="选择部门" prop="departId" label-width="80px" >
          <treeselect  v-model="addForm.departId"  class="vue-tree-select" :options="deptOptions" :show-count="true"  :normalizer="normalizer"
                      placeholder="选择部门" :disable-branch-nodes = "true"/>
        </el-form-item>
        <el-form-item
          label="岗位名称"
          prop="postName"
        >
          <el-input
            v-model="addForm.postName"
          />
        </el-form-item>

        <el-form-item
          label="招聘人数"
          prop="limitCount"
        >
          <el-input v-model="addForm.limitCount"/>
        </el-form-item>
        <el-form-item
          label="基础试题"
          prop="enable"
          label-width="80px"
        >
          <el-select multiple
                     remote
                     reserve-keyword
                     v-model="addForm.commonExamIds"
                     :remote-method="listCommonExams"
                     :loading="examsLoading"
                     filterable placeholder="请输入关键字">
            <el-option
              v-for="item in exams"
              :key="item.examId"
              :label="item.examName"
              :value="item.examId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="专业试题"
          prop="enable"
          label-width="80px"
        >
          <el-select multiple
                     remote
                     reserve-keyword
                     v-model="addForm.postExamIds"
                     :remote-method="listPostExams"
                     :loading="examsLoading"
                     filterable placeholder="请输入关键字">
            <el-option
              v-for="item in postExams"
              :key="item.examId"
              :label="item.examName"
              :value="item.examId"
            >
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="closeAddDialog">
          取 消
        </el-button>

        <template v-if="this.formOpt==='add'">
          <el-button
            type="primary"
            @click="addPost"
          >
            新增
          </el-button>
        </template>
        <template v-if="this.formOpt==='update'">
          <el-button
            type="primary"
            @click="updatePost"
          >
            修改
          </el-button>
        </template>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import utils from '@/utils/utils'
import post from '@/api/post'
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import depart from "@/api/department";

export default {
  name: 'PostManage',
  components: {Treeselect},
  data() {
    return {
      selectedDepart: '',
      selectedDepartName: '',
      options: [],
      // 部门树选项
      deptOptions: [],
      //试题数据
      exams: [],
      postExams: [],
      // 查询用户的参数
      queryInfo: {
        departId: '',
        postName: '',
        pageNo: 1,
        pageSize: 10
      },
      // 岗位信息
      postInfo: [{
        companyName: '',
        departName: '',
        postName: '',
        limitCount: '',
        openStatus: '',
        createTime: ''
      }],
      // 下拉选择框的数据
      optionInfo: [
        {
          label: '启用',
          desc: 'on'
        },
        {
          label: '禁用',
          desc: 'off'
        },
        {
          label: '删除',
          desc: 'delete'
        }
      ],
      // 下拉框所选择的数据
      selected: '',
      // 被选择的表格中的行数据
      selectedInTable: [],
      // 所有用户的条数
      total: 0,
      // 添加用户的对话框是否显示
      addTableVisible: false,
      formOpt: 'add',
      // 添加用户的表单信息
      addForm: {
        id: '',
        departId: null,
        postCode: '',
        postName: '',
        limitCount: '',
        postExamIds: [],
        commonExamIds: [],
      },
      // 添加用户表单的验证规则
      addFormRules: {
        postName: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: 'blur'
          }
        ],
        limitCount: [
          {
            required: true,
            message: '招聘人数',
            trigger: 'blur'
          }
        ]
      },
      /** 转换部门数据结构 */
      normalizer(node) {
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.id,
          label: node.departName,
          children: node.children
        };
      },
      // 表格信息加载
      loading: true,
      //试题信息加载
      examsLoading: false,
    }
  },
  created() {
    this.listPostInfo('init')
    this.getDepartInfo()
  },
  methods: {
    // 获取岗位信息
    listPostInfo(flag) {

      if ('init' !== flag) {
        const tmpDepartObj = this.$refs.comCascader.getCheckedNodes()
        if (tmpDepartObj.length > 0) {
          this.queryInfo.departId = tmpDepartObj[0].value
        }
        console.log(JSON.stringify(this.queryInfo))
      }
      post.listPost(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          this.postInfo = resp.data.data
          this.total = resp.data.total
          this.loading = false
        } else {
          this.$notify({
            title: 'Tips',
            message: '获取信息失败',
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    getDepartInfo() {
      post.listDepart({departName: ''}).then((resp) => {
        if (resp.code === 200) {
          this.deptOptions = resp.data
          this.loading = false
        } else {
          this.$notify({
            title: 'Tips',
            message: '获取信息失败',
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    // 浏览笔试题
    viewExam() {

    },
    listCommonExams(keyword) {
      this.listExams(keyword, 1)
    },
    listPostExams(keyword) {
      this.listExams(keyword, 2)
    },
    listExams(keyword, examType) {
      this.examsLoading = true;
      post.listExams({keyword: keyword, examType: examType}).then((resp) => {
        if (resp.code === 200) {
          if (examType === 1) {
            this.exams = resp.data
          } else {
            this.postExams = resp.data
          }
          this.examsLoading = false;
        } else {
          this.$notify({
            title: 'Tips',
            message: '获取信息失败',
            type: 'error',
            duration: 2000
          })
        }
      })

      return undefined;
    },
    // 表格某一行被选中
    handleSelectionChange(val) {
      this.selectedInTable = val
    },

    // 分页插件的大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.listPostInfo()
    },
    // 分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val
      this.listPostInfo()
    },
    // 点击添加按钮
    showAddDialog(id) {
      this.listCommonExams('')
      this.listPostExams('')
      this.getDepartInfo()
      this.formOpt = 'add'
      if (typeof id !== 'undefined' && id != null && id !== '') {
        post.getPostById({id: id}).then((resp) => {
          console.log(resp)
          this.formOpt = 'update'
          this.addForm.id = resp.data.id
          this.addForm.departId = resp.data.departId
          this.addForm.postCode = resp.data.postCode
          this.addForm.postName = resp.data.postName
          this.addForm.limitCount = resp.data.limitCount
          this.addForm.postExamIds = resp.data.postExamIds
          this.addForm.commonExamIds = resp.data.commonExamIds
        })
      }
      this.addTableVisible = true
    },
    closeAddDialog() {
      // 清空表格数据
      this.$refs.addForm.resetFields()
      this.addTableVisible = false
    },
    //删除岗位
    delPost(id) {
      post.handlerPost({id: id, enable: 3}).then((resp) => {
        if (resp.code === 200) {
          this.listPostInfo()
          this.$notify({
            title: 'Tips',
            message: resp.message,
            type: 'success',
            duration: 2000
          })
        } else {
          this.$notify({
            title: 'Tips',
            message: resp.message,
            type: 'error',
            duration: 2000
          })
        }
      })
    },
    //禁用岗位
    disablePost(id) {
      post.handlerPost({id: id, enable: 2}).then((resp) => {
        if (resp.code === 200) {
          this.listPostInfo()
          this.$notify({
            title: 'Tips',
            message: resp.message,
            type: 'success',
            duration: 2000
          })
        } else {
          this.$notify({
            title: 'Tips',
            message: resp.message,
            type: 'error',
            duration: 2000
          })
        }
      })
    },

    // 修改岗位
    updatePost() {
      utils.validFormAndInvoke(this.$refs.addForm, () => {
        post.updatePost(this.addForm).then((resp) => {
          if (resp.code === 200) {
            this.listPostInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'error',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请检查您所填写的信息是否有误')
    },
    // 新增岗位
    addPost() {
      utils.validFormAndInvoke(this.$refs.addForm, () => {
        post.addPost(this.addForm).then((resp) => {
          console.log(resp)
          if (resp.code === 200) {
            this.listPostInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'error',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请检查您所填写的信息是否有误')
    },
    // 表单信息重置
    resetAddForm() {
      // 清空表格数据
      this.$refs.addForm.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/admin/postExam";
</style>
