import request from '@/utils/request'

export default {
  listDepart (params) {
    return request({
      url: '/depart/tree',
      method: 'get',
      params: params
    })
  },
  listPost (params) {
    return request({
      url: '/advertisePost/list',
      method: 'get',
      params: params
    })
  },
  //获取考试列表
  listExams (params) {
    return request({
      url: '/exam/list',
      method: 'get',
      params: params
    })
  },
  getPostById (params) {
    return request({
      url: '/advertisePost/detail',
      method: 'get',
      params: params
    })
  },
  addPost (data) {
    return request({
      url: '/advertisePost/add',
      method: 'POST',
      data: data
    })
  },
  updatePost (data) {
    return request({
      url: '/advertisePost/modify',
      method: 'POST',
      data: data
    })
  },
  handlerPost (data) {
    return request({
      url: '/advertisePost/updateStatus',
      method: 'POST',
      data: data
    })
  }
}
